<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>E-Invoice Documents</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    :placeholder="String(supplier.selected)"
                    label="Supplier"
                    v-model="supplier.selected"
                    @change="execFilter('supplier')"
                    :items="supplier.items"
                    item-text="name"
                    item-value="id"
                    :rules="supplier.rule"
                    :loading="supplier.loader"
                    :disabled="supplier.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <vc-date-picker
                    :disabled-dates='dayplusone'
                     :key="counter1" v-model="starttime.item" mode="date" is-range update-on-input :popover="{visibility: 'click'}" @drag="cleardayplusone($event)" @input="getList(1)" :columns="$screens({ default: 1, lg: 2 })" :masks="{ input: 'DD-MM-YYYY' }">
                      <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-text-field
                                @click="togglePopover({ placement: 'bottom-start' })"
                                :value="inputValue.start != null ? `${inputValue.start} - ${inputValue.end}` : ''"
                                @keyup="starttime.item.start = ''; starttime.item.end = ''"
                                dense
                                v-on="inputEvents"
                                hide-details="auto"
                                label="From Date - To Date"
                                outlined
                                autocomplete="off"
                                hint="You can only select date range not more than 1 month."
                                persistent-hint
                            ></v-text-field>
                        </template>
                      </vc-date-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items.sync="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        item-key="ProfileID"
                    >
                    <template slot="item" slot-scope="props">
                        <tr :class="props.item.checkbox ? ['custom-selected'] : ''">
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>    
                            <td>
                                <v-tooltip top  >
                                    
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="viewMore(props.item, 'update')" style="text-decoration: underline" id="custom-hover">{{props.item.id}}</a>
                                    </template>
                                    <span>View Invoice: {{props.item.id}}</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <v-tooltip top v-if="props.item.Document.billPdfUrl != null">
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="viewMore(props.item, 'view_pdf')" style="text-decoration: underline" id="custom-hover">{{props.item.eInvoiceNumber}}</a>
                                    </template>
                                    <span>View PDF: {{props.item.eInvoiceNumber}}</span>
                                </v-tooltip>
                                <span v-else>{{props.item.eInvoiceNumber}}</span>

                            </td>
                            <td>{{props.item.Supplier.name}}</td>
                            <td>{{Number(props.item.Document.finalTotal).toFixed(2)}}</td>
                            
                            <td>{{props.item.Document.Constraints_InvoiceType.name}}</td>
                            <td>{{props.item.createdAt}}</td>
                            <td>{{props.item.Document.createdAt}}</td>
                            <td>{{props.item.Document.submissionId}}</td>
                            <td>
                                {{props.item.Document.isCancel ? 'Yes' : 'No'}}
                            </td>
                            <td>
                                {{props.item.Document.status != null ? props.item.Document.status : 'N/A'}}
                                <!-- <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.Document.status, 'color', 'status')"
                                text-color="white">
                                    {{statusCheck(props.item.Document.status, 'text', 'status')}}
                                </v-chip> -->
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <addprofile :dialog="ma.modal_profile" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></addprofile>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :category="ma.category" :issue="ma.issue" @confirm="onConfirm" @closed="onClosed"></create>
        <update :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :category="ma.category" :issue="ma.issue" @confirm="onConfirm" @closed="onClosed" @delete="onDelete"></update>
        <viewpdf :dialog="ma.modal_pdf" :customText="ma.customText" :modalInfo="ma.modalInfo" :pdfurl="pdfurl" @closed="onClosed"></viewpdf>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import alert from '.././modals/AlertStatus';
import update from '.././modals/ViewEInvoiceBill';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import alertdelete from '../modals/AlertDeleteProfile';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import viewpdf from '.././modals/ModalPDF2';

export default {
    name: 'EInvoiceDocuments',
    title: 'E-InvoiceDocuments',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Finance',
                disabled: true,
                href: '#',
                },
                
                {
                text: 'EInvoice',
                disabled: true,
                href: '#',
                },

                {
                text: 'E-Invoice Documents',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            /*
            #, ID, User ID, Name, Email ID, Email, CPO ID, Operator, Profile ID, Role, Created Date, Last Login Date, Status :warning: 
            */
            headers: [
                //{ text: '', value: 'checbox', width: '3%' },
                { text: '#', value: 'row', width: '2%' },
                { text: 'E-Bill ID', value: 'id', width: '2%' },
                { text: 'E-Bill No.', value: 'eInvoiceNumber', width: '2%' },
                { text: 'Supplier Name', value: 'Supplier.name', width: '2%' },
                { text: 'Total Amount (RM)', value: 'name', width: '3%' },
                { text: 'E-Bill Type', value: 'name', width: '5%' },
                { text: 'E-Bill Date', value: 'name', width: '3%' },
                { text: 'Sent Date', value: 'name', width: '3%' },
                { text: 'Submission ID', value: 'name', width: '3%' },
                { text: 'Cancel', value: 'name', width: '3%' },
                { text: 'Status', value: 'createdAt', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_delete: false,
                modal_profile: false,
                modal_pdf: false,
                readonly: true,
                allInfo: [],
                item: {}
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            supplier: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            dropitems: {},
            totalrecords: '',
            tableselected: [],
            pdfurl: '',
            starttime: {item: {start: '', end: ''}},
            endtime: {item: ''},
            starttimeforapi: '',
            endtimeforapi: '',
            dayplusone: [],
            show: false,
            counter1: 0,
        }
    },

    components: {alert, alertbox2, alertdelete,  update, viewpdf},
    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getList();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        }
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

            else{
                return '';
            }
        },
    },

    async created(){
        //await this.getHeaders();
        this.getSystemFull();
        await this.getOperatorFull();
        await this.getSupplierFull();
        await this.getList(1);
    },

    methods: {

         async getList(pg){
            await this.setTime();
            this.counter1++;
            this.tableloading = true;
            const response = await this.$store.dispatch("getLhdnInvoiceDocuments", {page: pg > 0 ? this.page = pg : this.page, search : this.search, startDate: this.starttimeforapi, endDate: this.endtimeforapi, supplierId: this.supplier.selected});
            console.log(response.data)
            
            this.totalrecords = response.data.count;
            this.items = response.data.record;
            for(let i = 0; i<this.items.length; i++){
                this.items[i].createdAt = moment(this.items[i].createdAt).format('DD-MM-YYYY hh:mm:ss A');
                this.items[i].updatedAt = this.items[i].updatedAt != null ?  moment(this.items[i].updatedAt).format('DD-MM-YYYY hh:mm:ss A') : 'N/A';
                this.items[i].Document.createdAt = this.items[i].Document.createdAt != null ?  moment(this.items[i].Document.createdAt).format('DD-MM-YYYY hh:mm:ss A') : 'N/A';
            }
            this.pages = Math.ceil(this.totalrecords / 100);
            this.tableloading = false;

        },

        comparatorID(a, b) {
            let it = a.SystemID == b.SystemID;
            return it;
        },

        async getSystemFull(){
            let list = [];
            this.system.items = [];
            try{
                this.system.loader = true;
                this.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.system.items.push({name: "All Systems", SystemID: ""});
                    this.system.itemsFull.push({name: "All Systems", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;
                        this.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                this.system.selected = "";

                this.system.loader = false;
                this.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;

                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },
         async getSupplierFull(){
            let list = [];
            this.supplier.items = [];
            this.supplier.selected = "";

            try{
                this.supplier.loader = true;
                this.supplier.disable = true;

                let self = this;

                if(this.supplier.itemsFull.length == 0){
                    console.log('here');
                    let response = await this.$store.dispatch("getLhdnSupplierList", {page: 1, search: ''});
                    let dt = response.data.record;
                    console.log(dt)
                    this.supplier.items.push({name: "All Supplier", id: ""});
                    this.supplier.itemsFull.push({name: "All Supplier", id: ""});
                    for(let i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].id} - ${dt[i].name}`;
                        this.supplier.items.push({name: dt[i].name, id: dt[i].id});
                        this.supplier.itemsFull.push(dt[i]);
                    }

                    this.supplier.selected = this.supplier.items[0].id;
                }



                this.supplier.loader = false;
                this.supplier.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        getHeaders(){
            if (!this.validateAccess('businesscpo_usersdelete'))
                this.headers.splice(0, 1);

            else
                this.headers;
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'reset'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
            }
            if (flag == 'view_pdf'){
                this.ma.modalInfo = row;
                this.pdfurl = row.Document.billPdfUrl;
                this.ma.modalInfo.urltext = row.eInvoiceNumber;
                this.ma.modal_pdf = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewDelete(row, flag){
            console.log(row);
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewCreate(){
            this.$router.push({ name: "Create User"});
        },


        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_delete = value;
                this.ma.modal_profile = value;
                this.ex.modal = value;
                this.ma.modal_pdf = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.modal = false;
                this.addLhdnSetup(value);
            }
            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.updateLhdnSetup(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_status = false;
                    this.userReset();
                }

                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_update = false;
                    // this.deleteProfile();
                }
            }
        },
        
        onDelete(){
            this.ma.modal_update = false;
            this.deleteLhdnSetup();
        },

        async execFilter(type){
            if (type == 'supplier'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getList(1);
          }, 800);
        },

        validateArr(arr){
            let truth = arr.some(item => {
                return item === true;
            });

            return truth;
        },

        statusCheck(val, typ, col){
            if(col == 'cancel'){
                if (typ == 'text'){
                        if (val === true){ return 'Yes'}
                        else if (val === false){return 'No'}
                        else {return 'No'}
                }
    
                if (typ == 'color'){
                        if (val === true){ return 'red'}
                        else if (val === false){return 'green'}
                        else {return 'green'}
                }
            }
            if(col == 'status'){
                if (typ == 'text'){
                        if (val === 'Valid'){ return 'Valid'}
                        else if (val === 'Invalid'){return 'Invalid'}
                        else {return 'N/A'}
                }
    
                if (typ == 'color'){
                        if (val === 'Valid'){ return 'green'}
                        else if (val === 'Invalid'){return 'red'}
                        else {return 'orange'}
                }
            }
        },

        setTime(){
            let dte = new Date();
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start).setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date(moment(new Date()).subtract(31, 'days')).setHours(0, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            let endtime = this.starttime.item.end !== '' ? moment(new Date(this.starttime.item.end).setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date().setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss');
            if (this.starttime.item.start == ""){
                this.starttime.item.start = new Date(moment(starttime).add(1, 'days'));
                this.starttime.item.end = new Date(endtime);
            }
            console.log('starttime', starttime);
            console.log('endtime', endtime);
            this.starttimeforapi = starttime;
            this.endtimeforapi = endtime;

            // Add a day
            let dte2 = new Date(moment(endtime).add(1, 'days'))
            console.log('dte', new Date(moment(endtime).add(1, 'days')));
            this.dayplusone = null
        },

        cleardayplusone(v){
            let dte2 = new Date(moment(v.start).add(31, 'days'));
            this.dayplusone = [
                        {
                        start: dte2,
                        end: null
                        },
                    ];
        },
         isToday() {
            return moment(new Date()).format('DD-MM-YYYY');
        },
    }
}
</script>